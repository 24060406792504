import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import JustComments from 'gatsby-plugin-just-comments'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, id } = markdownRemark
  return (
    <Layout>
        <div className="blog-post-container">
            <div className="blog-post">
                <h1>{frontmatter.title}</h1>
                <h2>{frontmatter.date}</h2>
                <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
                />
                <JustComments
                  locale="de"
                  disablesociallogin="true"
                  pageid="test"
                  apikey="abe0a078-344f-4aa0-ad52-f6f65b02943d"
                />
            </div>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "de")
        path
        title
        tags
        category
      }
    }
  }
`